:root {
  --primary: linear-gradient(to bottom right, #333, #333);
  --secondary: gold;
  --text: #fff;
}

.video-library {
  min-height: 100vh;
  background-image: var(--primary);
  font-family: 'Poppins';
  color: var(--text);
}
.video-container {
  width: 100%;
  margin-top: 10rem;
  display: grid;
  grid-template-columns: 1.8fr 1.2fr;
  gap: 1rem;
  overflow: hidden;
  padding: 0.5rem 1rem;
  text-transform: capitalize;
}
.dark {
  background-color: #333;
}

@media screen and (max-width: 1524px) {
  .video-container {
    grid-template-columns: 1fr;
  }
  .video-playlist {
    margin: 4rem 0;
  }
  .section {
    padding: 7rem 3rem;
  }
}

.main-video,
.video-playlist {
  width: 100%;
  min-height: 700px;
}

.main-video .title {
  margin-top: 1rem;
  font-size: 2rem;
}

.video-playlist .title {
  padding-left: 1rem;
  font-size: 2rem;
}

.video-playlist > p {
  padding: 1rem;
  font-size: 1.5rem;
  color: var(--secondary);
}

.video-playlist .videos {
  height: 70%;
  overflow-y: auto;
}

.video-playlist .videos::-webkit-scrollbar {
  width: 0.4rem;
  border-radius: 0.4rem;
  background-color: #0005;
}

.video-playlist .videos::-webkit-scrollbar-thumb {
  border-radius: 0.4rem;
  background-color: #fff;
}

.video-playlist .videos .video {
  position: relative;
  width: 100%;
  height: 8rem;

  display: flex;
  justify-content: center;
  align-items: center;

  padding: 0 1rem;
  margin-top: 0.1rem;
  cursor: pointer;

  border-radius: 0.5rem;
}

.video-playlist .videos .video:hover {
  background-color: #0003;
}

.video-playlist .videos .video.active {
  background-color: #0003;
  color: var(--secondary);
}

.main-video iframe {
  width: 100%;
  height: 100%;
  border-radius: 0.5rem;
}

.video img {
  position: absolute;
  left: 1rem;
  top: 50%;
  transform: translateY(-50%);

  width: 2.5rem;
  height: 2.5rem;

  filter: invert(100%);
}

.video-playlist .videos .video.active img {
  filter: invert(100%) sepia(100%) saturate(2000%) hue-rotate(360deg);
}

.video p {
  margin-left: 2.5rem;
  font-size: 2rem;
}

.video h3 {
  width: 50rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  font: 500 1rem 'Poppins';
  padding: 0 0.5rem;
}

@media screen and (max-width: 730px) {
  .video-playlist,
  .main-video {
    width: 90%;
    min-height: 400px;
  }
}

@media screen and (max-width: 650px) {
  .video-playlist,
  .main-video {
    width: 80%;
    min-height: 400px;
  }
}

@media screen and (max-width: 600px) {
  .video-playlist,
  .main-video {
    width: 72%;
    min-height: 400px;
  }
}

@media screen and (max-width: 550px) {
  .video-playlist,
  .main-video {
    width: 70%;
    min-height: 400px;
  }
  .video-playlist .title {
    font-size: 1.5rem;
  }
  .video p {
    font-size: 1.5rem;
  }
}
