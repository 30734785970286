.about {
  width: 100%;
  min-height: 600px;
  display: grid;
  place-items: center;
}
.row {
  width: 100%;
  max-width: 1170px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 50px;
  overflow-x: hidden;
  padding: 5rem 0;
}

.row .imgWrapper {
  overflow: hidden;
  border-radius: 5px;
}
.row .imgWrapper img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: 0.3s;
}

.row .imgWrapper:hover img {
  transform: scale(1.05);
}
.row .contentWrapper {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-left: 30px;
}
.row .contentWrapper span.textWrapper {
  display: block;
  font-size: 24px;
  text-transform: capitalize;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 15px;
  margin-bottom: 70px;
  font-weight: 600;
  color: #af896d;
}
.row .contentWrapper span.textWrapper span {
  display: inline-block;
  background: #af896d;
  width: 70px;
  height: 5px;
}
.row .contentWrapper h2 {
  font-size: 40px;
  font-weight: 700;
  color: #383f47;
  padding-bottom: 20px;
}
.row .contentWrapper p {
  font-size: 16px;
  line-height: 25px;
  padding-bottom: 25px;
  color: #666;
}
.row .contentWrapper a {
  display: inline-block;
  text-decoration: none;
  text-transform: uppercase;
  background: #383f47;
  color: #fff;
  padding: 15px 40px;
  letter-spacing: 1px;
  user-select: none;
  font-size: 14px;
}
@media screen and (max-width: 1250px) {
  .row {
    grid-template-columns: 1fr;
  }
}
