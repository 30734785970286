.icon,
.icon a {
  transition: 0.3s ease;
}
.icon a:not(:last-child) {
  margin-bottom: 20px;
}
.icon a:hover {
  transform: scale(1.3);
}

.social-icons {
  z-index: 999;
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  top: 50%;
  transform: translateY(-50%);
  margin-left: 90px;
}
.social-icons a {
  color: #fff;
  font-size: 2.2em;
  margin: 1.5rem 0;
  transition: 0.3s ease;
}
.social-icons a:hover {
  transform: scale(1.3);
}
@media screen and (max-width: 1100px) {
  .social-icons {
    right: 0px;
    margin-right: 50px;
  }
}
