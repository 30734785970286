@import 'style/base.scss';
@tailwind base;
@tailwind components;
@tailwind utilities;
:host,
:root {
  --fa-font-regular: normal 400 1em/1 'Poppins';
  --fa-style-family-classic: 'Poppins';
  --fa-font-solid: normal 900 1em/1 'Poppins';
  --fa-style-family-brands: 'Poppins';
  --fa-font-brands: normal 400 1em/1 'Poppins';
}
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Poppins', -apple-system, BlinkMacSystemFont, 'Segoe UI',
    'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
    'Helvetica Neue', sans-serif;
  scroll-behavior: smooth;
}
body {
  margin: 0;
  font-family: 'Poppins', -apple-system, BlinkMacSystemFont, 'Segoe UI',
    'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
    'Helvetica Neue', sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.font-text {
  font-size: 1.8rem;
  color: #666;
}
.section {
  padding: 15rem 3rem;
  border-top: 1px solid #ddd;
  transition: transform 1s, opacity 1s;
  background-color: #f3f3f3;
}
.section__title {
  max-width: 80rem;
  margin: 0 auto 8rem auto;
}

.section__description {
  font-size: 1.8rem;
  font-weight: 600;
  text-transform: uppercase;
  color: $cafe-au-lait;
  margin-bottom: 1rem;
}
.section__header {
  font-size: 4rem;
  line-height: 1.3;
  font-weight: 500;
}

@media screen and (max-width: 550px) {
  .section__header {
    font-size: 2rem;
    line-height: 1.3;
    font-weight: 500;
  }
}
