.promise-wall {
  width: 100%;
  height: 100vh;
  background-image: url('~/public/images/promise.jpg');
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.promise-text {
  font-size: 5.5rem;
  line-height: 1.2;
}
.white {
  color: white !important;
}
@media screen and (max-width: 670px) {
  .promise-text {
    font-size: 4.5rem;
  }
}

@media screen and (max-width: 500px) {
  .promise-text {
    font-size: 3rem;
  }
}
