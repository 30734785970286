@import '../../style/base.scss';

.section--hidden {
  opacity: 0;
  transform: translateY(8rem);
}

/* SLIDER */
.slider {
  max-width: 100rem;
  height: 50rem;
  margin: 0 auto;
  position: relative;

  /* IN THE END */
  overflow: hidden;
}

.slide {
  position: absolute;
  top: 0;
  width: 100%;
  height: 50rem;

  display: flex;
  align-items: center;
  justify-content: center;

  /* THIS creates the animation! */
  transition: transform 1s;
}

.slide > img {
  /* Only for images that have different size than slide */
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.slider__btn {
  position: absolute;
  top: 50%;
  z-index: 10;

  border: none;
  background: rgba(255, 255, 255, 0.7);
  font-family: inherit;
  color: #333;
  border-radius: 50%;
  height: 5.5rem;
  width: 5.5rem;
  font-size: 3.25rem;
  cursor: pointer;
}

.slider__btn--left {
  left: 6%;
  transform: translate(-50%, -50%);
}

.slider__btn--right {
  right: 6%;
  transform: translate(50%, -50%);
}

.dots {
  position: absolute;
  bottom: 5%;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
}

.dots__dot {
  border: none;
  background-color: #b9b9b9;
  opacity: 0.7;
  height: 1rem;
  width: 1rem;
  border-radius: 50%;
  margin-right: 1.75rem;
  cursor: pointer;
  transition: all 0.5s;

  /* Only necessary when overlying images */
  /* box-shadow: 0 0.6rem 1.5rem rgba(0, 0, 0, 0.7); */
}

.dots__dot:last-child {
  margin: 0;
}

.dots__dot--active {
  /* background-color: #fff; */
  background-color: #888;
  opacity: 1;
}

/* TESTIMONIALS */
.testimonial {
  width: 65%;
  position: relative;
}

.testimonial::before {
  content: '\201C';
  position: absolute;
  top: -5.7rem;
  left: -6.8rem;
  line-height: 1;
  font-size: 20rem;
  font-family: inherit;
  color: $cafe-au-lait;
  z-index: -1;
}

.testimonial__header {
  font-size: 2.25rem;
  font-weight: 500;
  margin-bottom: 1.5rem;
}

.testimonial__text {
  font-size: 1.7rem;
  margin-bottom: 3.5rem;
  color: #666;
}

.testimonial__author {
  margin-left: 3rem;
  font-style: normal;

  display: grid;
  grid-template-columns: 6.5rem 1fr;
  column-gap: 2rem;
}

.testimonial__photo {
  grid-row: 1 / span 2;
  width: 6.5rem;
  border-radius: 50%;
}

.testimonial__name {
  font-size: 1.7rem;
  font-weight: 500;
  align-self: end;
  margin: 0;
}

.testimonial__location {
  font-size: 1.5rem;
}

.section__title--testimonials {
  margin-bottom: 4rem;
}

/* SIGNUP */
.section--sign-up {
  background-color: #37383d;
  border-top: none;
  border-bottom: 1px solid #444;
  text-align: center;
  padding: 10rem 3rem;
}

.section--sign-up .section__header {
  color: #fff;
  text-align: center;
}

.section--sign-up .section__title {
  margin-bottom: 6rem;
}

.section--sign-up .btn {
  font-size: 1.9rem;
  padding: 2rem 5rem;
}

@media screen and (max-width: 600px) {
  .dots {
    bottom: 0;
  }
  .testimonial__author {
    grid-template-columns: 1fr;
  }
}
