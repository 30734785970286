.bg-slider {
  z-index: 777;
  width: 100%;
  min-height: 100vh;
  position: relative;
}
.bg-slider .swiper-slide {
  position: relative;
  width: 100%;
  height: 100vh;
}
.bg-slider .swiper-slide img {
  width: 100%;
  height: 100vh;
  object-fit: cover;
  background-size: cover;
  background-position: center;
  pointer-events: none;
}
.swiper-slide .text-content {
  position: absolute;
  top: 25%;
  color: #fff;
  margin: 0 200px;
  transition: 0.3s ease;
}

.swiper-slide .text-content .title {
  font-size: 6rem;
  font-weight: 700;
  text-shadow: 0 5px 25px rgba(0, 0, 0, 0.1);
  margin-bottom: 20px;
  transform: translateY(-50px);
  opacity: 0;
}
.swiper-slide-active .text-content .title {
  transform: translateY(0);
  opacity: 1;
  transition: 1s ease;
  transition-delay: 0.3s;
  transition-property: transform, opacity;
}
.swiper-slide .text-content .title span {
  font-size: 2.3rem;
  font-weight: 300;
}
.swiper-slide .text-content p {
  max-width: 700px;
  font-size: 1.8rem;
  background: rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(10px);
  text-shadow: 0 5px 25px rgba(0, 0, 0, 0.1);
  padding: 20px;
  border-radius: 10px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  border-right: 1px solid rgba(255, 255, 255, 0.1);
  box-shadow: 0 5px 25px rgba(0, 0, 0, 20%);
  transform: translateX(-80px);
  opacity: 0;
}
.swiper-slide-active .text-content p {
  transform: translateX(0);
  opacity: 1;
  transition: 1s ease;
  transition-delay: 0.3s;
  transition-property: transform, opacity;
}
.swiper-slide .text-content .read-btn {
  border: none;
  outline: none;
  background: #fff;
  color: #222;
  font-size: 1.5em;
  font-weight: 500;
  padding: 8px 25px;
  display: flex;
  align-items: center;
  margin-top: 40px;
  border-radius: 10px;
  cursor: pointer;
  transform: translateX(50px);
  opacity: 0;
}
.swiper-slide-active .text-content .read-btn {
  transform: translateX(0);
  opacity: 1;
  transition: 1s ease;
  transition-delay: 0.3s;
  transition-property: transform, opacity;
}
.dark-layer:before {
  content: '';
  position: absolute;
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.2);
}
.bg-slider-thumbs {
  z-index: 777;
  position: absolute;
  bottom: 7em;
  left: 50%;
  transform: translateX(-50%);
  transition: 0.3s ease;
  width: 270px;
}
.thumbs-container {
  background: rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(10px);
  padding: 10px 3px;
  box-shadow: 0 5px 25px rgba(0, 0, 0, 20%);
}
.thumbs-container img {
  width: 50px;
  height: 35px;
  margin: 0 5px;
  border-radius: 5px;
  cursor: pointer;
}
.swiper-slide-thumb-active img {
  border: 1px solid #fff;
}
@media screen and (max-width: 1280px) {
  .swiper-slide .text-content .title {
    font-size: 3rem;
  }
}

@media screen and (max-width: 1100px) {
  .swiper-slide .text-content {
    margin: 0px 50px;
  }
  .bg-slider-thumbs {
    bottom: 3em;
  }
  .swiper-slide .text-content .title {
    font-size: 3rem;
  }
  .swiper-slide .text-content .title span {
    font-size: 1rem;
  }
  .swiper-slide .text-content p {
    font-size: 1.6rem;
    max-width: 550px;
  }
}

@media screen and (max-width: 800px) {
  .swiper-slide .text-content {
    margin: 0px 50px;
  }
  .bg-slider-thumbs {
    bottom: 3rem;
  }
}
@media screen and (max-width: 785px) {
  .social-icons {
    margin-right: 20px !important;
  }
  .swiper-slide .text-content {
    margin: 0px 20px;
  }
  .swiper-slide .text-content p {
    max-width: 550px;
    font-size: 1.4rem;
  }
  .swiper-slide .text-content .title {
    font-size: 2.5rem;
  }
}
@media screen and (max-width: 640px) {
  .swiper-slide .text-content p {
    max-width: 290px;
  }
}
