.nav a {
  transition: 0.3s ease;
  width: 0;
  margin-left: 3rem;
  font-size: 1.6rem;
}
.nav a:before {
  content: '';
  position: absolute;
  background: #fff;
  width: 0%;
  height: 3px;
  bottom: 0;
  left: 0;
  transition: 0.3s ease;
}
.nav a:hover:before {
  width: 100%;
}
