/* .hamburger {
  background: url('/src/images/hamburger.png') no-repeat;
  background-size: 30px;
  background-position: center;
  width: 40px;
  height: 40px;
  cursor: pointer;
  transition: 0.3s ease;
  display: none;
} */

@import './../../style/base.scss';

.header {
  z-index: 999;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  transition: 0.5s ease;
}
.logo {
  width: 10rem;
  height: 10rem;
}

@media screen and (max-width: 840px) {
  .logo {
    height: 8rem;
    width: 8rem;
  }
  .nav {
    display: none;
  }
  .nav a {
    margin-left: 1.5rem;
  }
  .hamburger {
    display: block;
  }
}
