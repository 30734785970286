.gallery {
  position: relative;
  height: auto;
  min-height: 600px;
  width: 100%;
}
.gallery-container {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  max-width: 1000px;
  width: 100%;
  height: 600px;
  padding: 50px;
  background-color: #f5f5f5;
  box-shadow: 0 30px 50px #dbdbdb;
  overflow: hidden;
}
#gallerySlide {
  width: max-content;
  margin-top: 50px;
}
.gallery-item {
  width: 70px;
  height: 100px;
  background-position: 50% 50%;
  display: inline-block;
  transition: 0.5s;
  background-size: cover;
  position: absolute;
  z-index: 1;
  top: 70%;
  transform: translate(0, -50%);
  border-radius: 20px;
  box-shadow: 0 30px 50px #505050;
}
.gallery-item:nth-child(1),
.gallery-item:nth-child(2) {
  left: 0;
  top: 0;
  transform: translate(0, 0);
  border-radius: 0;
  width: 100%;
  height: 100%;
  box-shadow: none;
}
.gallery-item:nth-child(3) {
  left: 50%;
  opacity: 0;
}
.gallery-item:nth-child(4) {
  left: calc(50% + 120px);
  opacity: 0;
}
.gallery-item:nth-child(5) {
  left: calc(50% + 240px);
  opacity: 0;
}
.gallery-item:nth-child(n + 6) {
  left: calc(50% + 360px);
  opacity: 0;
}
.gallery-item .content {
  position: absolute;
  top: 50%;
  left: 100px;
  width: 300px;
  text-align: left;
  padding: 0;
  color: #eee;
  transform: translate(0, -50%);
  display: none;
  font-family: system-ui;
}
.gallery-item:nth-child(2) .content {
  display: block;
  z-index: 11111;
}
.gallery-item .name {
  font-size: 40px;
  font-weight: bold;
  opacity: 0;
  animation: showcontent 1s ease-in-out 1 forwards;
}
.gallery-item .des {
  margin: 20px 0;
  opacity: 0;
  font-size: 16px;
  animation: showcontent 1s ease-in-out 0.3s 1 forwards;
}
.gallery-item {
  button {
    padding: 10px 20px;
    border: none;
    opacity: 0;
    border: 1px solid #fff;
    animation: showcontent 1s ease-in-out 0.6s 1 forwards;
  }
  button:hover {
    background-color: #fff;
    color: #333;
  }
}
@keyframes showcontent {
  from {
    opacity: 0;
    transform: translate(0, 100px);
    filter: blur(33px);
  }
  to {
    opacity: 1;
    transform: translate(0, 0);
    filter: blur(0);
  }
}
.buttons {
  position: absolute;
  bottom: 30px;
  z-index: 222222;
  text-align: center;
  width: 100%;
}
.buttons button {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  border: 1px solid #fff;
  transition: 0.5s;
  margin: 2px;
}
.buttons button:hover {
  background-color: #fff;
}
.buttons button:hover i {
  color: #333;
}

@media screen and (max-width: 768px) {
  .gallery-item {
    width: 100px;
    height: 150px;
    top: 70%;
  }
  .gallery-item:nth-child(4) {
    left: calc(50% + 120px);
  }
}
