@import '../../style/base.scss';

.footer-container {
  background-color: $dark-grey-3;
  padding: 5rem 0;
  font-size: 1.8rem;
  color: #fff;
  position: relative;
  .wave {
    position: absolute;
    top: -100px;
    left: 0;
    width: 100%;
    height: 100%;
    background: #f00;
  }
  .center {
    text-align: center;
  }
  .logo-box {
    margin-bottom: 8rem;
    .footer-logo {
      width: 15rem;
      height: auto;
      display: inline-block;
    }
  }
  .footer-box {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    font-size: 1.8rem;
    margin-bottom: 7rem;
    .fbc-box {
      width: 40%;
      .fbc-text {
        width: 53%;
      }
    }
    .box {
      width: 30%;
      padding: 20px 30px;
    }
    .footer-header {
      font-size: 2rem;
      font-weight: 500;
      margin-bottom: 2rem;
      text-transform: capitalize;
      position: relative;
      margin-bottom: 3rem;
      border-bottom: 4px solid $cafe-au-lait;
      display: inline-block;
    }
  }
  .quote,
  .links li,
  p {
    line-height: 3rem;
    font-size: 1.8rem;
  }
  .copyright {
    font-size: 1.3rem;
  }
  .links ul li a {
    color: #fff;
    text-decoration: none;
    font-weight: 300;
    transition: all 0.3s ease;
  }
  .links ul li a:hover {
    color: #fff;
    padding-left: 8px;
  }
  .address-links {
    margin-top: 2rem;
    a {
      display: inline-block;
      height: 40px;
      width: 40px;
      background-color: rgba(255, 255, 255, 0.2);
      margin: 0 10px 10px 0;
      text-align: center;
      line-height: 40px;
      border-radius: 50%;
      color: #fff;
      transition: all 0.5s ease;
    }
    a:hover {
      color: #24262b;
      background-color: #fff;
    }
  }
}
@media screen and (max-width: 1536px) {
  .footer-box {
    flex-direction: column !important;
    .fbc-box,
    .box {
      width: 100% !important;
      padding: 20px 30px;
      .fbc-text {
        width: 100% !important;
      }
    }
  }
}
